import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    new bootstrap.Popover(this.element, {
      content: this.data.get("content"),
      html: this.data.get("html") == "true" ? true : false,
      placement: this.data.get("placement") || "top",
      trigger: this.data.get("trigger") || "click",
      customClass: this.data.get("custom-class") || "",
      allowList: Object.assign(bootstrap.Tooltip.Default.allowList, {
        details: [],
        summary: [],
        dl: [],
        dd: [],
        dt: [],
      }),
    });
  }
}
