import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source"];

  copy(event) {
    event.preventDefault();

    navigator.clipboard.writeText(this.sourceTarget.value);
    let tooltip = new bootstrap.Tooltip(event.target, {
      title: "Copied!",
      trigger: "manual",
    });
    tooltip.show();
    event.target.addEventListener("hidden.bs.tooltip", () => tooltip.dispose());
    setTimeout(() => tooltip.hide(), 1500);
  }
}
