import { Controller } from "@hotwired/stimulus";

// Allows modals to have buttons without data-bs-dismiss='modal' which
// will still close the modal on click. Adding the `data-bs-dismiss` on
// any button/link will prevent the default behavior triggered by the click
// and will just dismiss the modal.
// Adding the `data-modal-close-on-click` attribute to any such element within the
// modal will allow the default behavior to occur, while still dismissing the modal
// after the fact.
export default class extends Controller {
  connect() {
    const el = this.element;
    this.element
      .querySelectorAll("[data-modal-close-on-click]")
      .forEach((button) => {
        button.addEventListener("click", () => {
          bootstrap.Modal.getInstance(el).hide();
        });
      });
  }
}
