import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  toggle(event) {
    const toggledCheckbox = event.target;
    let hiddenColumns = [];
    const toggledColumn = toggledCheckbox.dataset.columnName;
    this.element
      .querySelectorAll('input[type="checkbox"]:not(:checked)')
      .forEach((checkbox) => hiddenColumns.push(checkbox.dataset.columnName));

    this.element
      .closest("table")
      .querySelectorAll(`[data-visible-columns-column-name='${toggledColumn}']`)
      .forEach((column) => {
        column.classList.toggle("d-none", !toggledCheckbox.checked);
      });

    Cookies.set("hidden_columns", hiddenColumns.join(","), {
      path: window.location.pathname,
      expires: 365,
    });
  }
}
