import { Controller } from "@hotwired/stimulus";
import renderjson from "renderjson";

export default class extends Controller {
  connect() {
    const json = JSON.parse(this.data.get("json"));
    renderjson.set_show_to_level(this.data.get("showToLevel") || 1);
    renderjson.set_collapse_msg(() => "...");
    renderjson.set_max_string_length(50);
    this.element.appendChild(renderjson(json));
  }
}
